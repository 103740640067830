<script>
import { mapState } from 'vuex';
import { analytics } from '../firebase';

import subsciptionHelpers from '@/mixins/subscriptionHelpers';
import formatDate from '@/mixins/formatDate';
import toastMessage from '@/mixins/toast';
import featureEnabled from '@/mixins/featureFlag';

import authenticatedRequest from '@/lib/authenticatedRequest';
import { BIconPencilSquare, BSpinner, BIconCardImage, BIconUpload, BIconXCircleFill } from 'bootstrap-vue';
import allProvinces from 'provinces';
import filter from 'lodash/filter';

import Cropper from '../components/Cropper.vue';
import { storage } from '../firebase';

export default {
	name: 'Profile',
	components: {
		BIconPencilSquare,
		BSpinner,
		BIconCardImage,
		BIconUpload,
		Cropper,
		BIconXCircleFill
	},
	data() {
		return {
			showTransactions: false,
			addingUser: false,
			error: '',
			newUserEmail: '',
			message: '',
			conditional :{
					isNameUpdationEditable:false,
					isNameUpdationLoader:false,
					isLastNameUpdationEditable:false,
					isLastNameUpdationLoader:false,
					isOrgNameUpdationEditable:false,
					isOrgNameUpdationLoader:false,
					isLocationOfPracticeEditable:false,
					isLocationOfPracticeLoader:false,
					isPhoneNumberUpdationEditable:false,
					isPhoneNumberUpdationLoader:false,

			},
			loginProviderType:'',
			userDetailsEditable:{
				displayName:'',
				lastName:'',
				organizationName:'',
				locationOfPractice:'',
				phoneNumber:'',
			},
			imgSrc: null,
			roleAssociated: null
			// provinces
			
		};
	},
	computed: {
		...mapState(['user', 'userProfile']),
		userDisplayName(){
			return this.user.displayName ? this.user.displayName: '';
		},
		setDisplayName(){
			if(localStorage.getItem('displayName')){
					return localStorage.getItem('displayName');
			}else{
				return this.user.displayName;
			}
		},
		userLastName(){
			return this.user.lastName ? this.user.lastName: '';
		},
		setLastName(){
			if(localStorage.getItem('userLastName')){
					return localStorage.getItem('userLastName');
			}else{
				return this.user.userLastName;
			}
		},
		phoneNumber(){
			return this.user.phoneNumber ? this.user.phoneNumber: '';
		},
		setphoneNumber(){
			if(localStorage.getItem('phoneNumber')){
					return localStorage.getItem('phoneNumber');
			}else{
				return this.user.phoneNumber;
			}
		},
		organizationName(){
			return this.user.organizationName ? this.user.organizationName: '';
		},
		setOrganizationName(){
			if(localStorage.getItem('organizationName')){
					return localStorage.getItem('organizationName');
			}else{
				return this.user.organizationName;
			}
		},
		locationOfPractice(){
			return this.user.locationOfPractice ? this.user.locationOfPractice: '';
		},
		setLocationOfPractice(){
			if(localStorage.getItem('locationOfPractice')){
					return localStorage.getItem('locationOfPractice');
			}else{
				return this.user.locationOfPractice;
			}
		},
		assistants() {
			return this.userProfile.assistants;
		},
		Provinces(){
			const provinces = filter(allProvinces, { country: 'CA' });
			return provinces.filter((province)=>province.name !== "Quebec")
		},
		setEmail(){
			if(localStorage.getItem('loginProvider') == 'CLIO' || localStorage.getItem('isClioFetch') == 'true'){
				let clioUser = localStorage.getItem('clioUser');
				if(clioUser && JSON.parse(clioUser)){
					return JSON.parse(clioUser).email;
				}
			} else {
				if(localStorage.getItem('loggedInEmailID')){
					return localStorage.getItem('loggedInEmailID');
				}else{
					return this.user.email;
				}
				
			}
		}
		
	},
	mounted() {
		localStorage.setItem('displayName',this.userDisplayName);
		localStorage.setItem('userLastName',this.userLastName);
		localStorage.setItem('phoneNumber',this.phoneNumber);
		localStorage.setItem('organizationName',this.organizationName);
		localStorage.setItem('locationOfPractice',this.locationOfPractice);
		analytics.logEvent('screen_view', {
			firebase_screen: 'profile',
			firebase_screen_class: 'Profile',
		});
		this.$store.dispatch('fetchCurrentCustomerProfile')
		this.loginProviderType = localStorage.getItem('loginProvider')
		// if(localStorage.getItem("currentRoleLevel")){
		if(this.$store.getters.getCurrentRoleLevel){
			const currentRoleLevel = this.$store.getters.getCurrentRoleLevel ? this.$store.getters.getCurrentRoleLevel : {}//localStorage.getItem("currentRoleLevel")
			this.roleAssociated = currentRoleLevel.roleAssociated;
		}
		

		const storageRef = storage.ref();
		const userDocsRef = storageRef.child(`userDocs/${this.user.uid}-logo.png`);
		userDocsRef.getDownloadURL()
			.then((url) => {
				this.imgSrc = url
			})
			.catch(error => {
				if (error.code === 'storage/object-not-found') {
					return 
				} else {
					console.error("Error getting download URL:", error);
				}
			});

	},
	methods: {
		openFileInput() {
      		this.$refs.fileInput.click();
		},
		handleFileSelection(e) {
			const file = e.target.files[0];

			const fileSizeMB = file.size / (1024 * 1024);
			if (fileSizeMB > 10) {
				this.warningMessage("Warning","Please upload a file below 10MB");
				e.target.value = ""; 
			} else {
				const reader = new FileReader();
				reader.onload = () => {
					let image = reader.result;
					this.$bvModal.show('logo-editor')
					setTimeout(() => {
						this.$refs.editImg.$refs.cropper.replace(image);
						e.target.value = ''
					}, 0);
				};
				reader.readAsDataURL(file);
			}
			

		},
		handleImageSaved(url) {
			this.imgSrc = url;
		},
		getTransactions() {},
		async addUser() {
			this.addingUser = true;
			this.error = '';
			try {
				const {
					data: { success, error },
				} = await authenticatedRequest('POST', `/addAssistant`, {
					email: this.newUserEmail,
					uid: this.user.uid,
				});

				if (success) {
					this.newUserEmail = '';
					this.message = 'User was added.';
					this.$store.dispatch('fetchPermissions');
					this.message = '';
				} else {
					if (error === 'duplicate-user') {
						this.error =
							'This user has already been added to the agreement.';
					} else if (error === 'user-not-found') {
						this.error =
							"This user doesn't have a Divii account please get them to signup for an account.";
					} else if (error === 'error') {
						this.error = 'Something unexpected went wrong.';
					}
				}
				this.addingUser = false;
				this.newUserEmail = '';
			} catch (error) {
				console.error(error);
				this.addingUser = false;
				this.error = 'Something unexpected went wrong.';
			}
		},
		resetPassword(){
			this.$router.push({name:'resetpassword'}).catch((error) => {console.error("ERROR::",error)});
    },
		updateDisplayName(){
			this.conditional.isNameUpdationLoader = true;
			this.$store.dispatch('updateDisplayName', this.userDetailsEditable).then(()=>{
				this.conditional.isNameUpdationLoader = !this.conditional.isNameUpdationLoader;
				this.conditional.isNameUpdationEditable = !this.conditional.isNameUpdationEditable;
				this.successMessage('','First name updated successfully')
			}).catch((error)=>{
				if(error && error.message){
					console.error('updateDisplayName err' ,error.message);
					this.warningMessage('',error.message);
				}
					this.conditional.isNameUpdationLoader = false;
			})
		},
		updateLastName(){
			this.conditional.isLastNameUpdationLoader = true;
			this.$store.dispatch('updateLastName', this.userDetailsEditable).then(()=>{
				this.conditional.isLastNameUpdationLoader = !this.conditional.isLastNameUpdationLoader;
				this.conditional.isLastNameUpdationEditable = !this.conditional.isLastNameUpdationEditable;
				this.successMessage('','Last name updated successfully')
				this.forceLastName()
			}).catch((error)=>{
				if(error && error.message){
					console.error('updateLastName err' ,error.message);
					this.warningMessage('',error.message);
				}
					this.conditional.isLastNameUpdationLoader = false;
			})
		},
		updateOrganizationName(){
			this.conditional.isOrgNameUpdationLoader = true;
			this.$store.dispatch('updateOrganizationName', this.userDetailsEditable).then(()=>{
				this.conditional.isOrgNameUpdationLoader = !this.conditional.isOrgNameUpdationLoader;
				this.conditional.isOrgNameUpdationEditable = !this.conditional.isOrgNameUpdationEditable;
				this.successMessage('','Organization name updated successfully');
				this.forceOrgName()
			}).catch((error)=>{
					if(error && error.message){
						console.error('updateOrganizationName err' ,error.message);
						this.warningMessage('',error.message);
					}
					this.conditional.isOrgNameUpdationLoader = false;
			})
		},
		updateLocationOfPractice(){
			this.conditional.isLocationOfPracticeLoader = true;
			this.$store.dispatch('updateLocationOfPractice', this.userDetailsEditable).then(()=>{
				this.conditional.isLocationOfPracticeLoader = !this.conditional.isLocationOfPracticeLoader;
				this.conditional.isLocationOfPracticeEditable = !this.conditional.isLocationOfPracticeEditable;
				this.successMessage('','province name updated successfully');
				this.forceProvinceName()
			}).catch((error)=>{
					if(error && error.message){
						console.error('updateLocationOfPractice err' ,error.message);
						this.warningMessage('',error.message);
					}
					this.conditional.isLocationOfPracticeLoader = false;
			})
		},
		updatePhoneNumber(){
			this.conditional.isPhoneNumberUpdationLoader = true;
			this.$store.dispatch('updatePhoneNumber', this.userDetailsEditable).then(()=>{
				this.conditional.isPhoneNumberUpdationLoader = !this.conditional.isPhoneNumberUpdationLoader;
				this.conditional.isPhoneNumberUpdationEditable = !this.conditional.isPhoneNumberUpdationEditable;
				this.successMessage('','Phone number updated successfully')
			}).catch((error)=>{
				if(error && error.message){
					console.error('updatePhoneNumber err' ,error.message);
					this.warningMessage('',error.message);
				}
					this.conditional.isPhoneNumberUpdationLoader = false;
			})
		},
		openEditMode(field){
			if(field == 'DisplayName'){
				this.conditional.isNameUpdationEditable = !this.conditional.isNameUpdationEditable;
			}
			if(field == 'lastName'){
				this.conditional.isLastNameUpdationEditable = !this.conditional.isLastNameUpdationEditable;
			}
			if(field == 'OrganizationName'){
				this.conditional.isOrgNameUpdationEditable = !this.conditional.isOrgNameUpdationEditable;
			}

			if(field == 'Province'){
				this.conditional.isLocationOfPracticeEditable = !this.conditional.isLocationOfPracticeEditable;
			}
			if(field == 'phoneNumber'){
				this.conditional.isPhoneNumberUpdationEditable = !this.conditional.isPhoneNumberUpdationEditable;
			}
			this.userDetailsEditable = {...this.user};
		},
		forceLastName(){
				setTimeout(()=>{
					// DONT DELETE below 2 same lines. This is added intentionally to forcefully bind last name name value to screen
					this.conditional.isLastNameUpdationEditable = !this.conditional.isLastNameUpdationEditable;
					this.conditional.isLastNameUpdationEditable = !this.conditional.isLastNameUpdationEditable;
					this.userDetailsEditable = {...this.user};
				},500)
		},
		forceOrgName(){
				setTimeout(()=>{
					// DONT DELETE below 2 same lines. This is added intentionally to forcefully bind organization name value to screen
					this.conditional.isOrgNameUpdationEditable = !this.conditional.isOrgNameUpdationEditable;
					this.conditional.isOrgNameUpdationEditable = !this.conditional.isOrgNameUpdationEditable;
					this.userDetailsEditable = {...this.user};
				},500)
		},
		forceProvinceName(){
                setTimeout(() => {
					this.conditional.isLocationOfPracticeEditable = !this.conditional.isLocationOfPracticeEditable;
					this.conditional.isLocationOfPracticeEditable = !this.conditional.isLocationOfPracticeEditable;
					this.userDetailsEditable = {...this.user};
				}, 500);
		},
		deleteLogo(){
			const storageRef = storage.ref();
			const userDocsRef = storageRef.child(`userDocs/${this.user.uid}-logo.png`);
			userDocsRef
			.delete()
			.then(() => {
				this.imgSrc = "";
				this.successMessage('',"Logo deleted successfuly");

			})
		},
		addDataTestIdAndClassToModal() {
			const closeButton = document.querySelector('.close');
			if (closeButton) {
				closeButton.setAttribute('data-testid', 'close-logo-editor-modal');
			}

			const modal = document.getElementById('logo-editor___BV_modal_body_')
				if(modal){
					modal.classList.add('custom-scroll')
					modal.classList.add('scroll-small')
				}
		},
	},

	mixins: [subsciptionHelpers, formatDate, featureEnabled,toastMessage],
};
</script>

<template>
	<b-container>
		<b-row style="margin-top: 30px">
		<div class="d-flex flex-column col-md-4 col-sm-8 col-xs-12">
			<b-col 
				><b-card tag="article" class="mb-2">
					<b-row class="text-center">
						<b-col v-if="user">
							<b-avatar
								style="marginbottom: 12px"
								:src="user.photoURL"
								size="6rem"
							></b-avatar>
							<b-card-title v-if="user.displayName">{{ setDisplayName }}
							</b-card-title>
							<b-card-sub-title v-if="user.organizationName">
								Organization : {{ setOrganizationName }}
							</b-card-sub-title>
							<b-card-text>
								{{ setEmail }}
							</b-card-text>
						</b-col></b-row
					>
					<b-row class="justify-content-center my-4">
						<!-- <b-col sm="auto">
							<b-button
								size="sm"
								style="margintop: 12px"
								variant="primary"
								@click="$router.push({ name: 'subscription' })"
								>Subscription</b-button
							>
              </b-col> -->
						<!-- <b-col sm="auto" v-if="loginProviderType=='EMAIL'"> -->
							<b-button
								size="sm"
								style="margintop: 12px"
								variant="primary"
								@click="resetPassword"
								data-testid="reset-password"
								v-if="loginProviderType=='EMAIL'"
								>Reset Password</b-button
							>
						<!-- </b-col> -->
					</b-row>
				</b-card>
			</b-col>
			<b-col v-if="roleAssociated !== 'party'">
				<b-card tag="article" class="mb-2">
					<b-row class="text-center">
						<b-col>
							<b-card-title>Organization Logo</b-card-title>
							<div class="org-logo pt-3">
								<img v-if="imgSrc" :src="imgSrc" alt="org Image" class="logo-img" data-testid="org-logo-img"/>
								<b-icon-x-circle-fill v-if="imgSrc" data-testid="org-logo-delete" class="delete-icon" @click="deleteLogo"></b-icon-x-circle-fill>
								<b-icon-card-image v-else font-scale="7" variant="secondary"></b-icon-card-image>
							</div>
						</b-col>
					</b-row>
					<b-row class="justify-content-center">
							<b-button
								size="md"
								style="margintop: 12px"
								variant="primary"
								data-testid="upload-logo"
								@click="openFileInput"
								class="mt-3"
							>
								<b-icon-upload></b-icon-upload>&nbsp; Upload Logo</b-button>
						<p class="supported-formats mb-0 pt-2">(Supported formats : .svg, .png , .jpg, .jpeg, .raw, .heif)</p>
						<p class="supported-formats mb-0 pt-2">Maximum file size allowed : 10MB</p>
						<input type="file" ref="fileInput" accept="image/*" style="display: none" @change="handleFileSelection"/>
					</b-row>
				</b-card>
			</b-col>
		</div>
			<b-col xs="12" sm="12" md="8">
				<b-card tag="article" class="mb-2">
					<b-row>
						<b-col cols="3">
							<b>First Name</b>
						</b-col>
						<b-col cols="5">
							<b-input v-if="conditional.isNameUpdationEditable"
								v-model="userDetailsEditable.displayName"
								size="sm"
								placeholder="Enter your first name"
								data-testid="profile-first-name"
							></b-input>
							<template v-else>
								{{ setDisplayName }}
							</template>
						</b-col>
						<b-col cols="4">
								<b-icon-pencil-square  class="h4 mb-2" variant="info" v-if="!conditional.isNameUpdationEditable" @click="openEditMode('DisplayName')" data-testid="edit-full-name"/>
								<b-button size="sm" variant="primary" v-else @click="updateDisplayName" :disabled="conditional.isNameUpdationLoader" data-testid="save-full-name">
									<template v-if="conditional.isNameUpdationLoader">
										<b-spinner small type="grow"></b-spinner>
										Saving...
									</template>
									<template v-else>
										Save
									</template>
								</b-button>
						</b-col>
					</b-row>
					<hr/>
					<b-row>
						<b-col cols="3">
							<b>Last Name</b>
						</b-col>
						<b-col cols="5">
							<b-input v-if="conditional.isLastNameUpdationEditable"
								v-model="userDetailsEditable.lastName"
								size="sm"
								placeholder="Enter your last name"
								data-testid="profile-last-name"
							></b-input>
							<template v-else>
								{{ setLastName }}
							</template>
							<!-- {{ user }} -->
						</b-col>
						<b-col cols="4">
								<b-icon-pencil-square  class="h4 mb-2" variant="info" v-if="!conditional.isLastNameUpdationEditable" @click="openEditMode('lastName')" data-testid="edit-last-name"/>
								<b-button size="sm" variant="primary" v-else @click="updateLastName" :disabled="conditional.isLastNameUpdationLoader" data-testid="save-last-name">
									<template v-if="conditional.isLastNameUpdationLoader">
										<b-spinner small type="grow"></b-spinner>
										Saving...
									</template>
									<template v-else>
										Save
									</template>
								</b-button>
						</b-col>
					</b-row>
					<hr/>
					<b-row>
						<b-col cols="3">
							<b>Phone Number</b>
						</b-col>
						<b-col cols="5">
							<b-input v-if="conditional.isPhoneNumberUpdationEditable"
								v-model="userDetailsEditable.phoneNumber"
								size="sm"
								placeholder="Enter your phone number"
								data-testid="profile-phone-number"
							></b-input>
							<template v-else>
								{{ setPhoneNumber }}
							</template>
							<!-- {{ user }} -->
						</b-col>
						<b-col cols="4">
								<b-icon-pencil-square  class="h4 mb-2" variant="info" v-if="!conditional.isPhoneNumberUpdationEditable" @click="openEditMode('phoneNumber')" data-testid="edit-phone-numbwe"/>
								<b-button size="sm" variant="primary" v-else @click="updatePhoneNumber" :disabled="conditional.isPhoneNumberUpdationLoader" data-testid="save-phone-number">
									<template v-if="conditional.isPhoneNumberUpdationLoader">
										<b-spinner small type="grow"></b-spinner>
										Saving...
									</template>
									<template v-else>
										Save
									</template>
								</b-button>
						</b-col>
					</b-row>
					<hr />
					<b-row>
						<b-col cols="3">
							<b> Email </b>
						</b-col>
						<b-col cols="9">
							{{ setEmail }}
						</b-col>
					</b-row>
					<hr />
					<b-row>
						<b-col cols="3">
							<b> Type of user </b>
						</b-col>
						<b-col cols="9">
							{{ roleAssociated ? roleAssociated : 'Lawyer' }}
						</b-col>
					</b-row>
					<hr v-if="roleAssociated !== 'party'"/>
					<b-row  v-if="roleAssociated !== 'party'">
						<b-col cols="3">
							<b>Organization Name</b>
						</b-col>
						<b-col cols="5">
							<b-input v-if="conditional.isOrgNameUpdationEditable"
								v-model="userDetailsEditable.organizationName"
								size="sm"
								placeholder="Enter your organization name"
								data-testid="edit-org-name"
							></b-input>
							<template v-else>
								{{ setOrganizationName }}
							</template>
						</b-col>
						<b-col cols="4">
								<b-icon-pencil-square  class="h4 mb-2" variant="info" v-if="!conditional.isOrgNameUpdationEditable" @click="openEditMode('OrganizationName')" data-testid="edit-org-name"/>
								<b-button size="sm" variant="primary" v-else v-on:click.stop="updateOrganizationName" :disabled="conditional.isOrgNameUpdationLoader" data-testid="save-org-name">
									<template v-if="conditional.isOrgNameUpdationLoader">
										<b-spinner small type="grow"></b-spinner>
										Saving...
									</template>
									<template v-else>
										Save
									</template>
								</b-button>
						</b-col>
					</b-row>
					<hr />
					<b-row>
						<b-col cols="3">
							<b>{{ roleAssociated === 'party' ? 'Location' : 'Location of practice' }}</b>	
						</b-col>
						<b-col cols="5">
							<b-form-select
							    v-if="conditional.isLocationOfPracticeEditable"
								v-model="userDetailsEditable.locationOfPractice"
								:options="Provinces"
								text-field="name"
								value-field="name"
								data-testid="location-of-practice"
							>
								<template #first>
								<b-form-select-option :value="undefined" disabled>
									Province
								</b-form-select-option>
								</template>
							</b-form-select>
							<template v-else>
								{{ setLocationOfPractice }}
							</template>
						</b-col>
						<b-col cols="4">
								<b-icon-pencil-square  class="h4 mb-2" variant="info" v-if="!conditional.isLocationOfPracticeEditable" @click="openEditMode('Province')" data-testid="edit-location-of-practice"/>
								<b-button size="sm" variant="primary" v-else v-on:click.stop="updateLocationOfPractice" :disabled="conditional.isLocationOfPracticeLoader" data-testid="save-location-of-practice">
									<template v-if="conditional.isLocationOfPracticeLoader">
										<b-spinner small type="grow"></b-spinner>
										Saving...
									</template>
									<template v-else>
										Save
									</template>
								</b-button>
						</b-col>
					</b-row>
					<hr />
					<b-row>
						<b-col cols="3">
							<b> Subscription </b>
						</b-col>
						<b-col cols="5">
							{{
								hasActiveSubscription ? 'Active' : 'Free Trial'
							}}
						</b-col>
						<b-col cols="4">
							<b-button
								size="sm"
								style="margintop: 12px"
								variant="primary"
								@click="$router.push({ name: 'subscription' })"
								data-testid="see-subscription-options"
								>See Subscription Options</b-button
							>
						</b-col>
					</b-row>
				</b-card>
			</b-col>	
		</b-row>
		<b-modal id="logo-editor" title="Edit organization logo" :scrollable="true" size="lg" hide-footer no-close-on-backdrop @shown="addDataTestIdAndClassToModal">
			<Cropper ref="editImg" :currentUser="user"  @imageSaved="handleImageSaved"></Cropper>
		</b-modal>
		<!-- Assistants -->
		<!-- ASSISTANT CODE DELETED FOR THE TIME BEING. TO BE ADDED LATER -->
		<!-- <div v-if="hasAssistantSubscription">
			<b-row>
				<b-col xs="12" sm="12" md="8" offset-md="4">
					<h4 class="text-center">Firm Members</h4>
					<b-card class="mb-2">
						<b v-if="hasAvailableAssistantSeats">
							Seats remaining: {{ seatsRemaining }}
						</b>
						<b-form-input class="mt-3" placeholder="Firm Name" />
					</b-card>

					<b-card
						class="mb-2"
						v-for="assistant in assistants"
						:key="assistant.email"
					>
						<b-row>
							<b-col>
								<b>Assistant</b>
							</b-col>
							<b-col> {{ assistant.email }} </b-col>
						</b-row>
						<hr />
						<b-row>
							<b-col>
								<b>Status</b>
							</b-col>
							<b-col>
								{{ assistant.status.toUpperCase() }}
							</b-col>
						</b-row>
					</b-card>
					<b-card v-if="hasAvailableAssistantSeats" class="mb-5">
						<b-row>
							<b-col>
								<b-form-input
									id="new-assistant-email"
									name="new-assistant-email"
									placeholder="Email"
									v-model="newUserEmail"
								/>
							</b-col>
							<b-col>
								<b-button
									variant="primary"
									:disabled="addingUser"
									@click="addUser"
								>
									Add Assistant
								</b-button>
							</b-col>
						</b-row>
						<b-row v-if="message">
							<b-col>
								{{ message }}
							</b-col>
						</b-row>
						<b-row v-if="error">
							<b-col>
								{{ error }}
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
		</div> -->
		<b-row v-if="false">
			<b-col xs="12" sm="12" md="8" offset-md="4">
				<h4 class="text-center">Transactions</h4>

				<b-collapse
					id="transctions"
					v-model="showTransactions"
					class="mt-2"
				>
					<b-card class="mb-2">
						<b-row>
							<b-col>
								<b>Amount</b>
							</b-col>
							<b-col>
								{{ transction.amount_received }}
							</b-col>
						</b-row>
						<hr />
						<b-row>
							<b-col>
								<b>Date</b>
							</b-col>
							<b-col>
								{{ formatUnix(transction.created) }}
							</b-col>
						</b-row>
						<hr />
						<b-row>
							<b-col>
								<b> Status </b>
							</b-col>
							<b-col> {{ transction.status }} </b-col>
						</b-row>
					</b-card>
				</b-collapse>
			</b-col>
		</b-row>
	</b-container>
</template>

<style scoped>
.row{
	min-height: 2rem;
}
.bi-pencil-square {
	cursor: pointer;
	transition-duration: 0.5s;
	opacity: 1.0;
}
.card-subtitle{
	    padding: 0.25rem 0;
}

.org-logo{
	min-height: 8rem;
	position: relative;
	display: inline-block;
}

.logo-img{
	max-width: 8rem;
	max-height: 8rem;
}
.delete-icon{
	cursor: pointer;
	position: absolute;
	top: 6px;
	right: -8px; 
	color: black;
	border: 2px solid white; 
	border-radius: 50%;
	background-color: white;
	font-size: larger;
}

.delete-icon:hover{
	font-size: x-large;
}

.supported-formats{
	font-size: small;
	text-align: center;
	font-weight: 500;
}
</style>